<template>
  <div class="side-padding" style="padding-top: 10vh; text-align: center;">
    <p>Tippelaget.no er på pause. Vi får se om vi kommer tilbake.</p>
  </div>
  <div class="tab-content">
    <p>
      Kontakt oss på
      <a href="https://www.facebook.com/tippelaget.no">Facebook</a>.
    </p>
  </div>
</template>
